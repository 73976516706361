import Vue from 'vue';
import Router from 'vue-router';
import { authRoutes } from './auth.routes';
import store from '@/_store';
import { dashboardRoutes } from './dashboard.routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...authRoutes, ...dashboardRoutes]
});

router.beforeEach(async (to, from, next) => {
  // User is authenticated
  if (store.getters['user/isAuthenticated']) {
    let passReset = store.getters['user/getUser'].password_reset;
    // Redirect User to profile if password not yet reset
    if (!passReset && to.path !== '/profile') {
      next({
        name: 'profile',
        params: { extUrl: to.fullPath },
        query: { resetPass: 'true' }
      });
      if (from.path === '/profile') {
        window.location.reload();
      }
    } else {
      if (to.path === '/login' || to.path === '/') {
        // Redirect User to Dashboard from Login Page
        next({ name: 'dashboard', params: { extUrl: to.fullPath } });
      } else {
        next();
      }
    }
  }

  // User is unauthenticated & requesting private route or root route '/'
  else if (to.matched.some(record => record.meta.isPrivate) || to.path === '/') {
    next({ name: 'login', params: { nextUrl: to.fullPath } });
  }

  // User is unauthenticated & requesting public route
  else {
    next();
  }
});

export default router;
