import { getters } from '@/_store/modules/loader/getters';
import { actions } from '@/_store/modules/loader/actions';
import { mutations } from '@/_store/modules/loader/mutations';

export const state = {
  isLoading: false,
  device: window.innerWidth < 768 ? 'is-mobile' : window.innerWidth < 1025 ? 'is-tablet' : 'is-desktop',
  isCollapsed: false
};

const namespaced = true;

export const loader = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
