import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { User } from '@/_store/modules/user/types';

export const getters: GetterTree<User, RootState> = {
  isAuthenticated(state) {
    return !!state.isAuthenticated;
  },

  isAuthorized(state) {
    return !!state.token;
  },

  getUser(state): any {
    return state.user;
  }
};
