import * as Sentry from '@sentry/vue';

function loopErrors(data: any, type: string) {
  let text = '';
  let context = '';

  // eslint-disable-next-line
  for (const [key, value] of Object.entries(data)) {
    switch (type) {
      case 'array':
        context = (value as any)[0];
        break;
      case 'object':
        context = value as any;
        break;
    }
    text += context;
  }
  return text;
}

export const messageGenerator = (error: any, location: string) => {
  let errorType: any = 'error';
  let message = 'defaultError';

  if (typeof error === 'object' && error.response) {
    const data = error.response.data;
    if (data.message) {
      message = data.message;
    } else if (data.affiliate_user_create_errors) {
      message = loopErrors(data.affiliate_user_create_errors, 'array');
    } else if (data.error) {
      message = loopErrors(data, 'object');
    }

    if (data.options && data.options.messageParams) {
      message = data.options.messageParams;
    }
  } else if (typeof error === 'string') {
    message = error;
  }

  /* 
  here is the example of setting up customise tracker for errors
  the errorType will be the color shown on sentry
  errorType = ['log', 'info', 'warn', 'error', 'debug', 'assert']
  message will be the message shown on sentry
  */

  Sentry.withScope(scope => {
    scope.setLevel(errorType);
    scope.setExtra('error', 'data');
    Sentry.captureException(new Error(`${message} at ${location}`));
  });
};
