export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'ログイン'
    },
    component: () => import('@/modules/auth/LoginForm.vue')
  }
];
