import { ActionTree } from 'vuex';
import { AffiliateUserState } from './types';
import { RootState } from '@/_store/types';
import { AffiliateUser, ThemeCode } from '@/_types/affiliateUser.types';
import Api from '@/services/affiliate.api';
import { messageGenerator } from '@/error';
import Vue from 'vue';
import i18n from '@/i18n';
import moment from 'moment';
import AdsistPartner from '@/modules/affiliateUsers/AffiliateUsers';

function showMessage(message?: any) {
  let toast: any = { message: '' };
  toast = {
    message: i18n.t('form.update_success') as string,
    type: 'is-success'
  };

  if (message) {
    toast.message = message;
    toast.type = 'is-danger';
  }

  Vue.prototype.$buefy.toast.open({ ...toast, duration: 1500 });
}

function combineUserData(users: AffiliateUser[], details: any) {
  users.map((user: AffiliateUser) => {
    let updates = details[user._id];

    user.total_amount_of_affiliate_fee = updates.total;
    user.total_number_of_users = updates.user_numbers;
    user.total_net_spent = updates.total_net_spent;
  });

  return users;
}

let period = {};
let globalFilter = {};
export const actions: ActionTree<AffiliateUserState, RootState> = {
  // eslint-disable-next-line
  getAffiliateUsersFromApi({ commit, rootState, state, dispatch }, filter: any) {
    commit('loader/setLoaderStatus', true, { root: true });
    globalFilter = filter;

    Api.getAllAffiliateUsers(filter)
      .then(response => {
        let records = response.data;
        records = records.map((record: AffiliateUser) => {
          record = new AdsistPartner(record);
          return record;
        });

        // Record the current period selection
        if (filter && filter.date_from) {
          // To skip the same period calculating
          if (filter !== period) {
            dispatch('getUsersDetail', filter);
          } else {
            records = combineUserData(records, state.userDetails);
          }

          period = {
            date_from: filter.date_from,
            date_to: filter.date_to
          };
        } else {
          if (Object.keys(state.userDetails).length > 1) {
            records = combineUserData(records, state.userDetails);
          }
          period = {};
        }

        commit('initialiseAffiliateUsersArray', records);
      })
      .catch(err => {
        messageGenerator(err, 'getAffiliateUsers');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  },

  getUsersDetail({ commit, state }, payload: any) {
    commit('updateLoadingDetails');
    Api.getAffiliateUserDetail(payload).then(res => {
      // Update the state users
      let users = [...state.users];

      users = combineUserData(users, res.data);

      commit('storeUserDetails', res.data);
      commit('initialiseAffiliateUsersArray', users);
      commit('updateLoadingDetails');
    });
  },

  // eslint-disable-next-line
  createUser({ commit, rootState }, payload: AffiliateUser) {
    payload.contract_started_date = new Date(moment(payload.contract_started_date).format('YYYY-MM-DD'));

    Api.createUser(payload)
      .then(response => {
        commit('createUser', response.data);
        showMessage();
        commit('closeModal', true);
      })
      .catch(err => {
        commit('loader/setLoaderStatus', false, { root: true });
        let errors = err.response.data.affiliate_user_create_errors;
        commit('storeErrors', errors);
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(errors)) {
          showMessage((value as any)[0]);
        }

        messageGenerator(err, 'createUser');
      });
  },

  updateUser({ commit, dispatch, state }, payload: AffiliateUser) {
    payload.contract_started_date = new Date(moment(payload.contract_started_date).format('YYYY-MM-DD'));

    payload.theme_code = (payload as any).theme_id;
    let user = state.users.filter(user => user._id === payload._id)[0];
    let hasChargeByUpdated = user.charge_by !== payload.charge_by;

    Api.updateUser(payload)
      .then(() => {
        showMessage();
        commit('closeModal', true);
        dispatch('getAffiliateUsersFromApi', period);

        if (hasChargeByUpdated) {
          dispatch('getUsersDetail', globalFilter);
        }
      })
      .catch(err => {
        commit('loader/setLoaderStatus', false, { root: true });
        let errors = err.response.data.affiliate_user_update_errors;
        commit('storeErrors', errors);

        // eslint-disable-next-line
        for (const [key, value] of Object.entries(errors)) {
          showMessage((value as any)[0]);
        }
        messageGenerator(err, 'updateUser');
      });
  },

  deleteUser({ dispatch }, userId: string) {
    Api.deleteUser(userId)
      .then(() => {
        showMessage();
        dispatch('getAffiliateUsersFromApi', period);
      })
      .catch(err => {
        messageGenerator(err, 'deleteUser');
      });
  },

  getThemeColorsFromApi({ commit }, filters) {
    commit('loader/setLoaderStatus', true, { root: true });

    Api.getAllThemes(filters)
      .then(response => {
        commit('initialiseThemeColorsArray', response.data);
      })
      .catch(err => {
        messageGenerator(err, 'getThemes');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  },

  createThemeColors({ commit, dispatch }, colors: ThemeCode) {
    commit('loader/setLoaderStatus', true, { root: true });

    Api.createTheme(colors)
      .then(() => {
        dispatch('getThemeColorsFromApi');
        showMessage();
        commit('closeModal', true);
      })
      .catch(err => {
        messageGenerator(err, 'createTheme');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  },

  updateThemeColors({ commit, dispatch }, colors: ThemeCode) {
    commit('loader/setLoaderStatus', true, { root: true });

    Api.updateTheme(colors)
      .then(() => {
        showMessage();
        commit('closeModal', true);
        setTimeout(() => {
          dispatch('getThemeColorsFromApi');
        }, 1000);
      })
      .catch(err => {
        messageGenerator(err, 'updateTheme');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  },

  deleteThemeColors({ commit, dispatch }, id: string) {
    commit('loader/setLoaderStatus', true, { root: true });

    Api.deleteTheme(id)
      .then(() => {
        dispatch('getThemeColorsFromApi');
      })
      .catch(err => {
        messageGenerator(err, 'deleteTheme');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  }
};
