import { getters } from '@/_store/modules/affiliateUser/getters';
import { actions } from '@/_store/modules/affiliateUser/actions';
import { mutations } from '@/_store/modules/affiliateUser/mutations';
import { AffiliateUserState } from './types';

export const state: AffiliateUserState = {
  users: [],
  themeColors: [],
  updateSuccessfully: false,
  userDetails: {},
  errors: [],
  loadingDetails: false
};

const namespaced = true;

export const affiliateUser = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
