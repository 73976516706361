import Vue from 'vue';

let handleOutsideClick: any;

Vue.directive('outsideClick', {
  bind(el, binding, vnode) {
    handleOutsideClick = (e: any) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      if (exclude.length > 0) {
        exclude.forEach((refName: string) => {
          if (!clickedOnExcludedEl) {
            const excludedEl = (vnode.context as any).$refs[refName];
            // Solved the typeError of contains = undefined
            if (excludedEl && excludedEl.contains(e.target)) {
              clickedOnExcludedEl = excludedEl.contains(e.target);
            }
          }
        });
      }

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        (vnode.context as any)[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  }
});
