import Api from './api';
import { Admin, CreateAdminRequest, AdminProfileRequest } from '@/_types/admin.types';

export const getAdmins = (filter?: any) => {
  return Api().get('/admin/admins', { params: { ...filter } });
};

export const getAdminByEmail = (email: string) => {
  return Api().get(`/admin/admins/${email}`);
};

export const createAdmin = (admin: CreateAdminRequest) => {
  return Api().post('/admin/admins/', admin);
};

export const checkPassword = (admin: AdminProfileRequest) => {
  return Api().post(`/admin/admins/checkPassword/${admin._id}`, { password: admin.old_password });
};

export const updateAdminAccount = (admin: any) => {
  return Api().put(`/admin/admins/${admin._id}`, admin);
};

export const resetPasswordAdminAccount = (id: string) => {
  return Api().put(`/admin/admins/resetPassword/${id}`);
};

export const updateAdminStatus = (admin: Admin) => {
  return Api().put(`/admin/admins/status/${admin._id}`, admin);
};

export const getLoginAdmin = () => {
  return Api().get('/admin/admins/loginAdmin');
};

export const deleteAdmin = (adminId: string) => {
  return Api().delete(`/admin/admins/${adminId}`);
};

export default {
  getAdmins,
  getAdminByEmail,
  createAdmin,
  checkPassword,
  updateAdminAccount,
  resetPasswordAdminAccount,
  updateAdminStatus,
  getLoginAdmin,
  deleteAdmin
};
