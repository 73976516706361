import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { Loader } from '@/_store/modules/loader/types';

export const getters: GetterTree<Loader, RootState> = {
  isLoading(state: any): boolean {
    return state.isLoading;
  },

  isMobile(state: any): boolean {
    if (state.device === 'is-mobile') {
      return true;
    }
    return false;
  },

  isTablet(state: any): boolean {
    if (state.device === 'is-tablet') {
      return true;
    }
    return false;
  },

  isDesktop(state: any): boolean {
    if (state.device === 'is-desktop') {
      return true;
    }
    return false;
  },

  getDevice(state: any): string {
    return state.device;
  },

  isCollapsed(state: any): boolean {
    return state.isCollapsed;
  }
};
