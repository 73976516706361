import { ActionTree } from 'vuex';
import { AdminState } from './types';
import { RootState } from '@/_store/types';
import { Admin } from '@/_types/admin.types';
import Api from '@/services/admin.api';
import { messageGenerator } from '@/error';
import AdminAccount from '@/modules/admins/AdminAccount';

export const actions: ActionTree<AdminState, RootState> = {
  // eslint-disable-next-line
  getAdminsFromApi({ commit, rootState }) {
    commit('loader/setLoaderStatus', true, { root: true });

    Api.getAdmins()
      .then(response => {
        let admins = response.data;
        admins = admins.map((admin: Admin) => {
          admin = new AdminAccount(admin);
          return admin;
        });
        commit('initialiseAdminsArray', admins);
      })
      .catch(err => {
        messageGenerator(err, 'getAdmins');
      })
      .finally(() => {
        commit('loader/setLoaderStatus', false, { root: true });
      });
  }
};
