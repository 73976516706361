export default {
  page_title: 'お知らせ',
  title: 'タイトル',
  content: 'コンテンツ',
  status: 'ステータス',
  target_page: 'ページ',
  target_page_code_home: 'ホームページ',
  target_page_code_dashboard: 'ダッシュボード',
  target_page_code_all: '両方',
  entity: 'お知らせ',
  type_no_set: '指定なし',
  type_notice: 'お知らせ',
  type_maintenance: 'メンテナンス',
  please_choose: '選択してください'
};
