import { AffiliateUser, ThemeCode } from '@/_types/affiliateUser.types';
import Api from './api';

export const getAllAffiliateUsers = (filter: any) => {
  return Api().get('/admin/affiliateusers/', { params: { ...filter } });
};

export const getAffiliateUserDetail = (filter: any) => {
  return Api().get('/admin/affiliateusers/details', { params: { ...filter } });
};

export const createUser = (userInfo: AffiliateUser) => {
  return Api().post('/admin/affiliateusers/', userInfo);
};

export const updateUser = (userInfo: AffiliateUser) => {
  return Api().put(`/admin/affiliateusers/${userInfo._id}`, userInfo);
};
export const deleteUser = (userId: string) => {
  return Api().delete(`/admin/affiliateusers/${userId}`);
};

export const getAllThemes = (filter: any) => {
  return Api().get('/admin/themecode/', { params: { ...filter } });
};

export const createTheme = (themeInfo: ThemeCode) => {
  return Api().post('/admin/themecode/', themeInfo);
};

export const updateTheme = (themeInfo: ThemeCode) => {
  return Api().put(`/admin/themecode/${themeInfo._id}`, themeInfo);
};
export const deleteTheme = (themeId: string) => {
  return Api().delete(`/admin/themecode/${themeId}`);
};

export const getCsvById = (filter: any) => {
  return Api().get('/admin/affiliateusers/csv/', { params: { ...filter } });
};

export default {
  getAffiliateUserDetail,
  getAllAffiliateUsers,
  createUser,
  deleteUser,
  updateUser,
  getAllThemes,
  createTheme,
  deleteTheme,
  updateTheme,
  getCsvById
};
