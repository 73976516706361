import { getters } from '@/_store/modules/admin/getters';
import { actions } from '@/_store/modules/admin/actions';
import { mutations } from '@/_store/modules/admin/mutations';
import { AdminState } from './types';

export const state: AdminState = {
  admins: []
};

const namespaced = true;

export const admin = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
