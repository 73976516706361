import { Admin } from '@/_types/admin.types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<any> = {
  initialiseAdminsArray(state, payload) {
    state.admins = payload;
  },

  createAdmin(state, admin: Admin) {
    state.admins.splice(state.admins.length, 0, admin);
  },

  updateAdmin(state, admin: Admin) {
    let stateAdmin = state.admins.find((item: Admin) => item._id === admin._id);
    if (stateAdmin) {
      Object.assign(stateAdmin, admin);
    }
  },

  deleteAdmin(state, adminId: string) {
    let index = state.admins.findIndex((admin: Admin) => {
      return admin._id === adminId;
    });

    if (index > -1) {
      state.admins.splice(index, 1);
    }
  }
};
