import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { AffiliateUserState } from './types';

export const getters: GetterTree<AffiliateUserState, RootState> = {
  getAffiliateUsers(state) {
    return state.users;
  },
  getThemes(state) {
    return state.themeColors;
  },
  getApiStatus(state) {
    return state.updateSuccessfully;
  },
  getErrors(state) {
    return state.errors;
  },
  getDetailsLoader(state) {
    return state.loadingDetails;
  }
};
