import { MutationTree } from 'vuex';

export const mutations: MutationTree<any> = {
  authenticateUser(state: any) {
    localStorage.removeItem('isAuthenticated');
    localStorage.setItem('isAuthenticated', JSON.stringify(true));
    state.isAuthenticated = true;
  },

  setToken(state, token: string) {
    localStorage.removeItem('jwt');
    localStorage.setItem('jwt', JSON.stringify(token));
    state.token = token;

    // Authorize User
    localStorage.removeItem('isAuthorized');
    localStorage.setItem('isAuthorized', JSON.stringify(true));
    state.isAuthorized = true;
  },

  setUser(state, user: any) {
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(user));
    state.user = user;
  },

  updateToken(state, token: string) {
    localStorage.setItem('jwt', JSON.stringify(token));
    state.token = token;
  },

  logUserOut(state: any) {
    state.token = null;
    state.isAuthorized = false;
    state.email = '';
    state.isAuthenticated = false;
    state.user = {};

    // Clear local storage
    localStorage.clear();
  }
};
