import { ActionTree } from 'vuex';
import { LoaderState } from '@/_store/modules/loader/types';
import { RootState } from '@/_store/types';

export const actions: ActionTree<LoaderState, RootState> = {
  setDevice({ commit }, device) {
    commit('setDevice', device);
  },

  toggleDashboardMenu({ commit }, mode) {
    commit('toggleDashboardMenu', mode);
  }
};
