import { MutationTree } from 'vuex';

export const mutations: MutationTree<any> = {
  setLoaderStatus(state: any, status: boolean) {
    state.isLoading = status;
  },

  setDevice(state: any, device: string) {
    state.device = device;
  },

  toggleDashboardMenu(state: any, mode: boolean) {
    if (mode) {
      return (state.isCollapsed = mode);
    }

    state.isCollapsed = !state.isCollapsed;
  }
};
