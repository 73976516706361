import { AffiliateUser, ChargeBy } from '@/_types/affiliateUser.types';

export default class AdsistPartner implements AffiliateUser {
  _id = '';
  name = '';
  tag = '';
  logo = {
    _id: '',
    user_id: '',
    normal: '',
    small: '',
    favicon: ''
  };
  theme_code = {
    _id: '',
    name: '',
    primary: '',
    secondary: '',
    success: '',
    backgroundDeep: '',
    backgroundSurface: '',
    textNormal: '',
    textActive: '',
    textHover: '',
    subtitleSide: '',
    paginationActive: '',
    dashboardClick: '',
    dashboardSpent: '',
    dashboardImpression: '',
    dashboardConversion: ''
  };
  fb_page_id = '';
  tel = '';
  email = '';
  theme_id = '';
  skin_feature = false;
  contract_started_date = new Date();
  company_url = '';
  affiliate_fees = [
    {
      case_until: 200,
      percentage: 20
    }
  ];
  total_number_of_users = 0;
  total_amount_of_affiliate_fee = 0;
  theme_code_id = '';
  updated_at = '';
  use_special_plans = false;
  charge_by = ChargeBy.totalUser;
  created_at = '';
  plans = [];

  constructor(user?: any) {
    let vm: any = this;

    if (user && Object.entries(user).length) {
      for (let key in user) {
        vm[key] = user[key];

        if (key === 'affiliate_user_id') {
          vm['_id'] = user.affiliate_user_id;
        }

        if (key === 'theme_code' && user.theme_code) {
          vm['theme_id'] = user.theme_code._id;
        }
      }
    }
  }
}
