import { Admin } from '@/_types/admin.types';

export default class AdminAccount implements Admin {
  _id = '';
  name = '';
  email = '';
  password = '';
  active = true;
  password_reset = false;

  constructor(admin?: any) {
    let vm: any = this;

    if (admin && Object.entries(admin).length) {
      for (let key in admin) {
        vm[key] = admin[key];
      }
    }
  }
}
