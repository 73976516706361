import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import store from '@/_store';

let showMessage = true;

export default () => {
  const instance = axios.create({
    baseURL: location.protocol + '//' + process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-with': 'XMLHttpRequest',
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('jwt') as string)
    }
  });

  instance.interceptors.response.use(
    (response: any) => {
      // If the response have a new token, refresh it.
      if (response.headers['authorization']) {
        store.dispatch('user/authorizeUser', {
          token: response.headers.authorization.split('Bearer ')[1]
        });
      }

      // Check app version
      if (response.headers['app-version'] && process.env.VUE_APP_VERSION !== response.headers['app-version']) {
        showMessageAndLogout('ブラウザーをリロードして、最新のAdsistをご利用いただけます。', 'snackbar');
      } else {
        return response;
      }

      return Promise.reject();
    },
    (error: any) => {
      if (error.response && 401 === error.response.status) {
        if (error.response.data.message) {
          showMessageAndLogout(error.response.data.message);
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

function showMessageAndLogout(message: string, type = 'toast') {
  if (!showMessage) {
    return;
  }

  showMessage = false;

  switch (type) {
    case 'snackbar': {
      Vue.prototype.$buefy.snackbar.open({
        message,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'OK',
        indefinite: true
      });
      break;
    }
    default: {
      Vue.prototype.$buefy.toast.open({
        type: 'is-danger',
        duration: 3000,
        message
      });
    }
  }

  // Logout the user
  store.dispatch('user/logUserOut');

  // Redirect to login or if on homepage stay there.
  if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'home') {
    router.push({ name: 'login' });
  }

  setTimeout(() => {
    showMessage = true;
  }, 200);
}
