import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { AdminState } from './types';
import { Admin } from '@/_types/admin.types';

export const getters: GetterTree<AdminState, RootState> = {
  getAllAdmins(state): Admin[] {
    return state.admins;
  },

  checkDuplicatedEmail(state): any {
    return (email: string) => {
      return state.admins.some((admin: Admin) => {
        return admin.email === email;
      });
    };
  },

  getAdmin(state): any {
    return (email: string) => {
      return state.admins.filter((admin: Admin) => {
        return admin.email === email;
      })[0];
    };
  }
};
