import { Plan } from './plan.types';

export interface AffiliateUser {
  _id: string;
  name: string;
  tag: string;
  email: string;
  tel: string;
  theme_code_id?: string;
  fb_page_id?: string;
  contract_started_date: Date | null;
  company_url: string;
  skin_feature: boolean;
  affiliate_fees: AffiliateFee[];
  updated_at?: string;
  created_at?: string;
  total_number_of_users?: number;
  total_amount_of_affiliate_fee?: number;
  total_net_spent?: number;
  logo?: Logo;
  theme_code?: ThemeCode;
  theme_id?: string;
  use_special_plans?: boolean;
  plans?: Plan[];
  charge_by: ChargeBy;
}

export enum ChargeBy {
  totalUser = 'total_user',
  totalNetSpent = 'total_net_spent'
}

export interface Logo {
  _id: string;
  user_id: string;
  normal: string;
  small: string;
  favicon: string;
  normal_img_path?: string;
  small_img_path?: string;
  favicon_img_path?: string;
}

export interface ThemeCode {
  _id: string;
  name: string;
  primary: string;
  secondary: string;
  success: string;
  backgroundDeep: string;
  backgroundSurface: string;
  textNormal: string;
  textActive: string;
  textHover: string;
  subtitleSide: string;
  paginationActive: string;
  dashboardClick: string;
  dashboardSpent: string;
  dashboardImpression: string;
  dashboardConversion: string;
}

export interface AffiliateFee {
  case_until: number;
  percentage: number;
}
