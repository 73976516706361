import { ActionTree } from 'vuex';
import { RootState } from '@/_store/types';
import { UserState } from '@/_store/modules/user/types';

export const actions: ActionTree<UserState, RootState> = {
  logUserIn({ commit }, { token, user }) {
    commit('authenticateUser');

    commit('setToken', token);
    commit('setUser', user ? user : {});
  },

  setUser({ commit }, user) {
    commit('setUser', user ? user : {});
  },

  authenticateUser({ commit }) {
    commit('authenticateUser');
  },

  authorizeUser({ commit }, { token }) {
    commit('setToken', token);
  },

  logUserOut({ commit }): any {
    commit('logUserOut');
  }
};
