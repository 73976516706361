import { getters } from '@/_store/modules/user/getters';
import { actions } from '@/_store/modules/user/actions';
import { mutations } from '@/_store/modules/user/mutations';

export const state = {
  token: localStorage.getItem('jwt') ? localStorage.getItem('jwt') : null,
  email: localStorage.getItem('email') ? JSON.parse(localStorage.getItem('email') as string) : null,
  isAuthorized: localStorage.getItem('isAuthorized')
    ? JSON.parse(localStorage.getItem('isAuthorized') as string)
    : false,
  isAuthenticated: localStorage.getItem('isAuthenticated')
    ? JSON.parse(localStorage.getItem('isAuthenticated') as string)
    : false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {}
};

const namespaced = true;

export const user = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
