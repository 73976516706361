import AdsistPartner from '@/modules/affiliateUsers/AffiliateUsers';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<any> = {
  initialiseAffiliateUsersArray(state, payload) {
    state.users = payload;
  },

  createUser(state, payload) {
    let user = { ...payload[0], ...payload[1] };
    user = new AdsistPartner(user);
    state.users.push(user);
  },

  initialiseThemeColorsArray(state, payload) {
    state.themeColors = payload;
  },

  closeModal(state, payload) {
    state.updateSuccessfully = payload;
  },

  storeUserDetails(state, payload) {
    state.userDetails = payload;
  },

  storeErrors(state, payload) {
    state.errors = payload;
  },

  updateLoadingDetails(state) {
    state.loadingDetails = !state.loadingDetails;
  }
};
